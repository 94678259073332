// COLOR
$color-primary: #3dba94;
$color-primary-2: #1ead85;
$color-primary-3: #05e7a3;
$color-secondary: #079fbf;
$color-secondary-2: #078ba5;
$color-secondary-3: #00cbf3;
$color-tertiary: #021938;

$color-danger: #f0231c;
$color-danger-bg: #fae3e6;
$color-info: #1399c5;
$color-info-bg: #e7faff;
$color-safe: #00ae86;
$color-safe-bg: #dffaf4;
$color-warning: #ffba08;
$color-warning-bg: #fff9ea;

$color-text: #231f20;
$color-white: #fff;
$color-black: #000;
$color-gray-1: #1b1b1b;
$color-gray-2: #2e2e2e;
$color-gray-3: #424242;
$color-gray-4: #575757;
$color-gray-5: #707070;
$color-gray-6: #8c8c8c;
$color-gray-7: #ababab;
$color-gray-8: #bdbdbd;
$color-gray-9: #d6d6d6;
$color-gray-10: #ebebeb;
$color-gray-11: #f6f6f6;

$color-status-1: #00ae86;
$color-status-1-bg: #dffaf4;
$color-status-2: #32a2c7;
$color-status-2-bg: #d5f6ff;
$color-status-3: #ef3e4a;
$color-status-3-bg: #fae3e6;

// FONTS
$font-title: "Noto Sans JP";
$font-text: "Noto Sans JP";
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-xbold: 900;

// TIME
$global-duration: 0.1s;

// LAYOUT
$content-max-width: 100rem;
