@import "../../styles/variable.scss";

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  animation: spin 1s linear infinite;
  border: 0.3rem solid;
  border-left-color: $color-gray-10;
  border-right-color: $color-gray-10;
  border-top-color: $color-gray-10;
  border-bottom-color: $color-secondary;
  border-radius: 50%;
  display: inline-block;
  height: 6rem;
  width: 6rem;
}
