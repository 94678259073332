@import "./variable.scss";

@mixin screen-md() {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin screen-lg() {
  @media screen and (min-width: 992px) {
    @content;
  }
}
@mixin screen-xl() {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin center-v {
  align-items: center;
  display: flex;
  flex-direction: row;
}
@mixin center-v-between {
  @include center-v;
  justify-content: space-between;
}
@mixin center-vh {
  @include center-v;
  justify-content: center;
}

@mixin center-h {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@mixin center-h-between {
  @include center-h;
  justify-content: space-between;
}
@mixin center-hv {
  @include center-h;
  justify-content: center;
}
