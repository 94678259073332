@tailwind base;
@tailwind components;
@tailwind utilities;

input,
input:focus,
textarea,
textarea:focus,
button,
button:focus,
select,
select:focus,
.chakra-checkbox__control,
.chakra-checkbox__control:focus,
.chakra-checkbox__label,
.chakra-checkbox__label:focus,
.chakra-switch__thumb,
.chakra-switch__thumb:focus,
.chakra-switch__track,
.chakra-switch__track:focus {
  outline: none !important;
  box-shadow: none !important;
}

// input,
// textarea,
// select {
//   border-color: #a0aec0 !important;
// }

// input:focus,
// textarea:focus,
// select:focus {
//   border-color: #4299e1 !important;
//   box-shadow: 0px 0px 6px rgba(66, 153, 225, 0.24) !important;
// }

.chakra-radio__label,
.chakra-alert__desc {
  font-size: 0.875rem !important;
}

th {
  font-family: Open Sans, sans-serif !important;
}
.react-datepicker__day-name {
  font-family: "Inter", sans-serif;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.number-input-01 {
  &:focus {
    box-shadow: unset !important;
    border-color: unset !important;
  }
}
