.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dashed #F0F0F0;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 32px;
  cursor: pointer;

  .normal-field {}
}

.first-field {
  display: flex;
  align-items: 'center';
  justify-content: "start";
}

.patient-index {
  padding: 2px;
  color: #F55B5B;
  font-size: 15px;
  font-weight: 600;
  background-color: #FEEFEF;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.table-item-container {
  display: flex;
  align-items: center;


  &:hover {
    background-color: #F0F0F0;
  }
}