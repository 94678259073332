$fontSizeContextPrint: 2;
$lineHeightContextPrint: 2.5;

@mixin convertPX2EM($fontSizePX, $lineHeightPX) {
  font-size: calc(($fontSizePX - $fontSizeContextPrint) / 16) * 1em;
  line-height: calc(($lineHeightPX - $lineHeightContextPrint) / 16) * 1em;
}

@mixin truncateText($linePerRow: 1) {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $linePerRow;
}

@page {
  size: A4;
  margin: 0;
}
.print {
  &-footer {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media print {
  body {
    margin: 0;
    padding: 0;
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .print {
    &-title {
      margin-bottom: 10pt !important;
    }
    &-content {
      padding-top: 60px;
      padding-bottom: 60px;
      min-height: calc(28.7cm);
    }
    &-footer {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .chakra-text {
    &.L2938 {
      @include convertPX2EM(24, 26);
    }
    &.TextBody1518 {
      @include convertPX2EM(15, 18);
    }
    &.TextBody1624 {
      @include convertPX2EM(16, 24);
    }
    &.TextBody1524 {
      @include convertPX2EM(15, 24);
    }
    &.TextBody2028 {
      @include convertPX2EM(20, 28);
    }
    &.TextBody1528 {
      @include convertPX2EM(15, 22);
    }
    &.Body1722 {
      @include convertPX2EM(17, 22);
    }
    &.Title_12432 {
      @include convertPX2EM(24, 32);
    }
    &.Title_31924 {
      @include convertPX2EM(19, 24);
    }
    &.Sub1318 {
      @include convertPX2EM(13, 18);
    }
  }
}
