.button-tool-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 42px;
  border-radius: 16px;
  background-color: #fff;
  cursor: pointer;
}

.button-tool-container:hover {
  background-color: #f9f9f9;
}
