.ref-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 270px;
  margin-right: 24px;

  .image {
    width: 270px;
    height: 155px;
    border-radius: 8px 8px 8px 0;
    position: relative;
  }

  .title {
    font-size: 17px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .description {
    color: #969799;
    font-size: 15px;
    font-weight: 400;
  }
}